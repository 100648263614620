//Node Modules
import { useNavigate } from "react-router-dom";

export default function ProductCard({ product }) {
  const productName =
    product === "dm3400" ? "dm3400/dm3401/dm3600/dm3601" : product;

  const navigate = useNavigate();
  function handleGetQuote() {
    navigate("/contact");
  }

  return (
    <div className="product-card">
      <img src={`../assets/${product}.png`} alt={product} />
      <span>{productName}</span>
      <div className="button-holder">
        <button onClick={() => handleGetQuote()}>Get a quote</button>
        <a href={`../assets/${product}.pdf`} target="_blank" rel="noreferrer">
          View Brochure
        </a>
      </div>
    </div>
  );
}
